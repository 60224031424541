import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './article-preview.module.css'
import styled from 'styled-components';
const ArticleImg = styled(Img)`
  margin-top: 20px;
`
const ArticleTitle = styled('h2')`
  margin-top: -20px;
  margin-bottom: 20px;
  z-index: 20;
  left: 0px;
  position: relative;
`;
export default ({ article, className }) => (
  <div className={styles.preview}>
      { article.heroImage ? (
          <Link className={styles.articleLink} to={`/blog/${article.slug}`}>
              <ArticleImg alt="" fluid={article.heroImage.fluid} />
              <ArticleTitle>{article.title}</ArticleTitle>
          </Link>
      ) : <></> }


    <small>{article.publishDate}</small>
    <p
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
    { article.tags && article.tags.length > 0 &&
      article.tags.map(tag => (
      <p className={styles.tag} key={tag}>
        {tag}
      </p>
    ))}
  </div>
)
