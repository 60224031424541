import React from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import LayoutSimple from '../components/layout-simple'
import ArticlePreview from '../components/article-preview'
import styled from 'styled-components'
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Img from "gatsby-image"
import tokens from '../tokens'
import Molly from '../components/molly'
import Dex from '../components/dex'
import Bonnie from '../components/bonnie'
import Nel from '../components/nel'
import Indie from '../components/indie'
import Luna from '../components/luna'
import "../utils/normalize.css"
import "../utils/css/screen.css"
import Container from "../components/container";
import Grid from 'styled-components-grid';

class RootIndex extends React.Component {


  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const heroImage = get(this, 'props.data.heroImage.childImageSharp.fluid')
    const dexImage = get(this, 'props.data.dexImage.childImageSharp.fluid')
    const lunaImage = get(this, 'props.data.lunaImage.childImageSharp.fluid')
    const indieImage = get(this, 'props.data.indieImage.childImageSharp.fluid')
    const nelImage = get(this, 'props.data.nelImage.childImageSharp.fluid')
    const bonnieImage = get(this, 'props.data.bonnieImage.childImageSharp.fluid')
    const mollyImage = get(this, 'props.data.mollyImage.childImageSharp.fluid')
    const myrtleImage = get(this, 'props.data.myrtleImage.childImageSharp.fluid')
    const evieImage = get(this, 'props.data.evieImage.childImageSharp.fluid')
    



    const ArticleList = styled('ul')`
      li h2 { 
        padding-left: 20px;
        padding-right: 20px;
        color: white !important;
        
      }
      li h3 {
        margin: 0px;
      }
      li:nth-child(n) {
          
         h2 {
          transform: rotate(-2deg); 
          background: ${tokens.brand('molly')} !important;
         }
         
      }
      small {
        
      }
      li:nth-child(2n) {
         
          h2 {
            transform: rotate(2deg); 
              background: ${tokens.brand('nel')} !important; 
          }
          
      }
      li:nth-child(3n)  {
        h2 { 
              background: ${tokens.brand('bonnie')} !important; 
          }
        
      }

     li:nth-child(7n)  {
        h2 { 
              background: ${tokens.brand('bonnie')} !important; 
          }
        
      }
      li:nth-child(4n) {
          h2 {
          transform: rotate(-2deg) translateY(0px);; 
              background: ${tokens.brand('indie')} !important; 
          }
      }
      li:nth-child(5n) {
        h2 {
          transform: rotate(-2deg) translateY(0px);;
          background: ${tokens.brand('dex')} !important;
        }
      }
    `;

    const ImgRounded = styled(Img)`
      border-radius: 10px;
    `;

    const GridExtended = styled(Grid)`
      padding: 0px;
      padding-top: 20px;
      > div {
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 70px;
        color: white;
        font-weight: bold;
        figure {
          position: relative;
        }
         transition: all 0.3s ease;
        figcaption {
          margin: 0 -20px;
          padding: 20px;
          background: rgba(0,0,0,0.2);
          position: absolute;
          bottom: -95px;
          left: 0px;
          right: 0px;
        }
        a {
           color: white !important;
        }
        &:first-child {
           transform: rotate(-2deg); 
           a { 
            background: ${tokens.brand('nel')}; !important;
           }
           background: ${tokens.brand('nel')};
        }
        &:nth-child(2n) {
            transform: rotate(-5deg);
            a { 
                background: ${tokens.brand('indie')}; !important; 
            }
            background: ${tokens.brand('indie')};
        }
        &:nth-child(3n)  {
         a { 
                background: ${tokens.brand('luna')}; !important; 
            }
            transform: rotate(3deg)  translateY(20px);
             background: ${tokens.brand('luna')};
        }
        &:nth-child(4n) {
         a { 
                background: ${tokens.brand('bonnie')}; !important; 
            }
            transform: rotate(-7deg) translateY(10px);;
             background: ${tokens.brand('bonnie')};
        }
        &:nth-child(5n) {
          a {
            background: ${tokens.brand('molly')}; !important;
          }
          transform: rotate(-7deg) translateY(10px);;
          background: ${tokens.brand('molly')};
        }
        &:nth-child(6n) {
          a {
            background: ${tokens.brand('dex')}; !important;
          }
          transform: rotate(-7deg) translateY(10px);;
          background: ${tokens.brand('dex')};
        }
        &:hover {
          &:first-child {
           transform: rotate(-3deg);
          }
          &:nth-child(2n) {
              transform: rotate(-4deg);
            
          }
          &:nth-child(3n)  {
           transform: rotate(2deg) translateY(20px);
              
          }
          &:nth-child(4n) {
              transform: rotate(-6deg) translateY(10px);
          }
          &:nth-child(5n) {
            transform: rotate(-6deg) translateY(10px);
          }
        }
           
      }
    `;

    const Polaroid = styled(Link)`
    `;

    const GridUnit = styled(Grid)`
      padding: 20px;
      
    `

    return (
        <LayoutSimple location={this.props.location}>
          <div style={{background: '#fff'}}>
            <Helmet title={siteTitle}/>


            <GridExtended>

              <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 3}}>
                <Polaroid  to="/gallery/Nel-golden-doodle" style={{margin: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={nelImage}

                        className="kg-image"
                    />
                    <figcaption><span>Nel</span> a medium sized ultimate doodle with fleecy, non shedding coat and
                      wonderful nature. Nel’s puppies will be small medium to medium sized reds , apricots and
                      creams
                    </figcaption>
                  </figure>
                </Polaroid>
              </Grid.Unit>

              <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 3}}>
                <Polaroid  to="/gallery/Indie-from-Puppy" style={{margin: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={indieImage}

                        className="kg-image"
                    />
                    <figcaption><span>Kingscotedoodle Indie</span>, our miniature, Ultimate Double Doodle with supersoft, curly, non
                      shedding coat, full of fun and supersmart. Indies puppies will be miniature reds and parti reds.
                    </figcaption>
                  </figure>
                </Polaroid>
              </Grid.Unit>

              <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 3}}>
                <Polaroid to="/gallery/luna-gallery" style={{margin: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={lunaImage}
                        className="kg-image"
                    />
                    <figcaption><span >Kingscotedoodle Luna</span>, our medium sized Ultimate Double Doodle with
                      a red, soft wavy, non shedding coat and gentle temperament. Luna’s puppies will be miniatures
                      and medium sized reds and apricots
                    </figcaption>
                  </figure>
                </Polaroid>
              </Grid.Unit>


                <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 2}}>
                <Polaroid to="/gallery/Evie-goldendoodle" style={{padding: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={evieImage}
                        className="kg-image"
                    />
                    <figcaption><span >Evie</span>  Ultimate Doodle - Medium/larger size at 22 inches weighing 23kg
                    </figcaption>
                  </figure>

                </Polaroid>
              </Grid.Unit>

                <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 2}}>
                <Polaroid to="/gallery/Myrtle-Miniature-Golden-Doodle" style={{margin: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={myrtleImage}
                        className="kg-image"
                    />
                    <figcaption><span >Myrtle</span> Miniature Australian Double Doodle (Daughter of Indie and Rufus)
                    </figcaption>
                  </figure>
                </Polaroid>
              </Grid.Unit>

             <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 5}}>
                <Polaroid to="/gallery/Bonnie-Labradoodle" style={{padding: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={bonnieImage}
                        className="kg-image"
                    />
                    <figcaption><span >Bonnie</span>  Daughter to Molly and half sister to Indie.  <b>Now Retired</b>
                    </figcaption>
                  </figure>

                </Polaroid>
              </Grid.Unit>

              <Grid.Unit  size={{tablet: 1 / 2, desktop: 1 / 5}}>
                <Polaroid to="/gallery/Molly-labradoodle" style={{margin: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={mollyImage}
                        className="kg-image"
                    />
                    <figcaption><span >Molly</span> Our medium sized, curly coated Labradoodle, mother to Indie. <b>Now Retired</b>
                    </figcaption>
                  </figure>
                </Polaroid>
              </Grid.Unit>



               <Grid.Unit size={{tablet: 1 / 2, desktop: 1 / 5}}>
                <Polaroid to="/gallery/dexter-gallery" style={{margin: 5 + 'px'}}>
                  <figure className="">
                    <ImgRounded
                        fluid={dexImage}
                        className="kg-image"
                    />
                    <figcaption><span >Kingscotedoodle Dex</span>, our Border Collie. He rounds up our Doodles.
                    </figcaption>
                  </figure>
                </Polaroid>
              </Grid.Unit>





            </GridExtended>

            <svg id="svg-sprite" style={{height: 0 + 'px'}}>
              <symbol id="paw" viewBox="0 0 249 209.32">
                <image xlinkHref={'/paw.png'} x="0" y="0" height="100px" width="100px"/>
              </symbol>
            </svg>

            <div className="ajax-loader">
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>

              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
              <div className="paw">
                <svg className="icon">
                  <use xlinkHref="#paw"/>
                </svg>
              </div>
            </div>

            <article className="post-content page-template no-image">
              <div className="post-content-body">


                <h2>
                  Kingscotedoodles - The home of top quality, health tested Ultimate Doodles, Labradoodles and
                  Goldendoodles

                </h2>

                <nav id="swup">
                  <ul className="nav" role="menu">
                    <li className="nav-home nav-current" role="menuitem">
                      <Link to={`/about-kingscote-doodles`}>Meet Our Family</Link>
                    </li>
                    <li className="nav-elements" role="menuitem">
                      <Nel to={`/gallery/Nel-golden-doodle`}>Nel</Nel>
                    </li>
                    <li className="nav-elements" role="menuitem">
                      <Indie to={`/gallery/Indie-from-Puppy`}>Indie</Indie>
                    </li>
                    <li className="nav-about" role="menuitem">
                      <Luna to={`/gallery/Luna-labradoodle`}>Luna</Luna>
                    </li>
                    <li className="nav-about" role="menuitem">
                      <Bonnie to={`/gallery/Bonnie-Labradoodle`}>Bonnie</Bonnie>
                    </li>
                    <li className="nav-about" role="menuitem">
                      <Molly to={`/gallery/Molly-labradoodle`}>Molly</Molly>
                    </li>
                    <li className="nav-elements" role="menuitem">
                      <Indie to={`/gallery/Evie-goldendoodle`}>Evie</Indie>
                    </li>
                    <li className="nav-about" role="menuitem">
                      <Luna to={`/gallery/Myrtle-Miniature-Golden-Doodle`}>Myrtle</Luna>
                    </li>
                  </ul>
                </nav>

                <p>
                  At Kingscotedoodles, we ethically and lovingly breed healthy, robust, excellent temperament
                  puppies that grow to become loving and loyal lifelong companions that any family can be proud.

                  <br /><br />
                  Licensed, Experienced and Qualified Breeders
                  Kingscotedoodles are 5 star (Higher Standard) licensed (Torbay Local Authority 008407),
                  qualified; (OFQUAL Level 3 Certificate in Dog Breeding, Litter Socialisation and Welfare
                  ipetnetwork), ethical breeders of Doodles, including: Miniature and Medium sized
                  DoubleDoodles, Labradoodles and Goldendoodles.


                </p>
                <p><a target="_blank" href="https://www.facebook.com/kingscote.labradoodles/" target="_blank">Facebook Page</a> and our <a target="_blank" href="https://www.facebook.com/kingscote.labradoodles/" target="_blank">Facebook Group</a></p>


                <figure className="kg-card kg-image-card kg-width-full">
                  <Img
                      fluid={heroImage}
                      className="kg-image"
                  />
                  <figcaption>
                  </figcaption>
                </figure>
                <h3 id="dynamic-styles">Who we are</h3>
                <p>

                  We are local authority Higher Standard, licensed dog breeders; a husband and wife team, recently retired  as a Headteacher and Manager,  so we can now devote all our time to our beautiful doodles and human grandchildren. Our dogs are our life and our passion and in our many years of experience, we can confirm that Doodles are simply wonderful, loyal, loving dogs. They are intelligent and caring and make excellent family pets. Doodles come in many shapes, sizes, coat types and colours and we can help choose the right puppy and personality for your family.

                  <h4>Our Support</h4> The service that we provide is second to none, involving and supporting new families in every step of the process. We help you to prepare for welcoming your new addition. You can be assured that we at Kingscote Doodles have our dogs and puppies best interest at heart, and we go above and beyond to provide you with daily updates, pictures and videos through our <a href="https://www.facebook.com/kingscote.labradoodles/">Facebook Page</a> and our <a href="https://www.facebook.com/kingscote.labradoodles/">Facebook Group</a>


                  Once you take home your puppy, weekly zoom calls are available for the  first month and thereafter, a life time of support, for as long as you choose. Our facebook group is a great source of support and a fantastic community of Kingscotedoodle families always keen to share their own tips or seek advice. Doodle meetings are also an annual  feature,when you can meet other Doodle owners and enjoy a few hours of fun and exercise, and at the same time share stories of your beautiful doggies!

                  <h4>Raising Our Puppies</h4>

                  <h5>The PreNatal Period</h5>

                  Making sure that your puppy’s genetic material is excellent is, however, only the beginning. The physical and emotional health of the mother will affect the health of her puppies. Since research has shown that puppies born to mothers that receive prenatal massage are more docile and enjoy being touched, we spoil our mothers with lots of affection, cuddles, enrichment and massages and tummy rubs. A puppy’s predisposition to form deep and meaningful relationships begins even before they are born.


                  <h4>Puppy Culture</h4>

                  <p>Our puppies are raised using an extensive, research based, structured programme called  <a href="https://www.puppyculture.com" target="_blank" >Puppy Culture</a></p>

                  <p>The first 12 weeks of a puppy’s life are incredibly important. This is an almost magical time when a breeder has the power to change the outcome of a puppy’s life by what we expose them to and choose to teach him. By doing just the right things at just the right time, we can give your puppy the best start possible.</p>


                  <h4>The Neonatal Period</h4>

                  <p>The programme begins with Early Neurological Stimulation (ENS) between Days 3 and continues through to day 16. Research shows that gentle tiny amounts of stress e.g. tickling between puppies' toes with a cotton wool bud,  in appropriate small doses, are actually good for puppies and will help them grow into strong, resilient, confident and healthy well-adjusted adults. Benefits include greater tolerance to stress, greater resistance to disease, faster adrenal system, stronger heart rate and stronger heartbeat. This is a gift that a breeder can only give their puppies once during the window of 3-16 days.</p>



                  <p>The Critical Socialization Period: 3-12 weeks.</p>

                  <p>Most people think of socialization as exposing their puppies to as many new experiences as possible while the puppy is young. While this is part of the process, it’s not enough. Our goal is to raise dogs that have the emotional intelligence to connect with you and resilience to connect with the human world. There are 7 key things that will nurture the emotional intelligence and resilience of a puppy.</p>


                  <ul>
                  <li>1: Communication – giving a puppy his own voice (Communication Trinity – (power up clicker, box game, manding), attention/distraction protocols)</li>

                    <li>2: Emotional stability – the ability to recover easily from fear as well as stress (startle recovery,  barrier challenges, Volhard Aptitude Test at 6 weeks)</li>

                      <li>3: Habituation – familiarity with the maximum number of things (Puppy Parties, sound protocols, habituation soundtracks and noises, meeting different people, dogs, other animals)</li>

                        <li>4: Enrichment – the view that novelty and challenges are opportunities for enrichment rather than things to be feared or avoided (novelty items, Adventure Box, off premises socialization)</li>

                          <li>5: Health – physical wellness and motor skills that will allow the puppy to develop in a neurologically and physically sound way (daily weight checks, grooming, vaccinations, deworming, proper nutrition, vet health checks)</li>

                            <li>6: Skills – learned behaviors which allow him to function in human society (recall, manding, simple commands, litter box training, crate training, leash walking, resource guarding, bite inhibition)</li>

                              <li>7: Love – the desire to seek out the company of both dogs and humans as emotionally positive experiences (shaping emotional responses, Happy and Calm CER (Conditioned Emotional Responses), daily cuddles with humans and mom).</li>
                  </ul>


                  This is a tremendous amount of work, but we are sure that you will agree that these protocols are  what will set your puppy apart, giving puppy the best possible start and improving his or her life forever.






                </p>
              </div>

            </article>


            <Container>

              <div className="wrapper">

                <h2 className="section-headline">Recent Doodle Adventures</h2>
                <ArticleList className="article-list">
                  {posts.map(({ node }) => {
                    return (
                        <li key={node.slug}>
                          <ArticlePreview article={node} />
                        </li>
                    )
                  })}
                </ArticleList>


              </div>


              <Heading element='h5'>
                We love sharing the latest Labradoodle and Goldendoodle adventures we've been on.
              </Heading>


            </Container>
          </div>
        </LayoutSimple>
    )
  }
}

export default RootIndex

// https://www.gatsbyjs.org/docs/page-query/
export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: {fields: [publishDate], order: ASC}) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 620, maxHeight: 420, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
#    allContentfulPerson(filter: {contentful_id: {eq: "15jwOBqpxqSAOy2eOO4S0m"}}) {
#      edges {
#        node {
#          name
#          shortBio {
#            shortBio
#          }
#          title
#          heroImage: image {
#            fluid(
#              maxWidth: 1180
#              maxHeight: 480
#              resizingBehavior: PAD
#              background: "rgb:000000"
#            ) {
#              ...GatsbyContentfulFluid_tracedSVG
#            }
#          }
#        }
#      }
#    }


    lunaImage: file( relativePath: {eq: "luna-adult.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    dexImage: file( relativePath: {eq: "dex-border-collie-in-wheat-field.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    mollyImage: file( relativePath: {eq: "molly-with-labradoodle-puppy.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    indieImage: file( relativePath: {eq: "indie-labradoodle-looking.jpeg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    nelImage: file( relativePath: {eq: "nel-picture.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bonnieImage: file( relativePath: {eq: "bonnie-labdraoodle-dog-kingscote.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

     myrtleImage: file( relativePath: {eq: "myrtle-golden-doodle.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }

   evieImage: file( relativePath: {eq: "evie-standing-vignette.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 920) {
          ...GatsbyImageSharpFluid
        }
      }
    }



    heroImage: file(  relativePath: {eq: "jumping-puppy.jpg"} ) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
